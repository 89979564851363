<template>
  <div>
    <vs-col vs-w="12" class="pb-5">
      <vs-row vs-type="flex" vs-w="3" vs-sm="3" vs-xs="12">
        <h1 class="primary font-bold" style="color: #555555">Drugbook</h1>
      </vs-row>
    </vs-col>
    <div>
      <h3 class="primary" style="color: #555555">Clinic Setup</h3>
      <div class="w-full py-5">
        <p>
          Select the clinics you would like to set up digital drugbooks for.
        </p>
        <p class="text-sm">
          You can complete clinic drugbook setup at anytime.
        </p>
      </div>
      <div class="bg-white rounded-lg p-5">
        <!-- <h4 class="primary" style="color: #555555">Clinic</h4> -->
        <div>
          <ul class="w-full flex flex-wrap">
            <li
              v-for="clinic in clinicsList"
              :key="clinic._id"
              class="w-1/2 hidden lg:block"
            >
              <div class="rounded hover:shadow-md p-5 relative">
                <h4 class="leading-loose">{{ clinic.name }}</h4>
                <p class="leading-loose">
                  {{ clinic.address ? clinic.address.displayAddress : "" }}
                </p>

                <vs-button
                  class="mt-2"
                  v-if="!selectedClincsList.includes(clinic.clinicId)"
                  v-on:click="addSelected(clinic.clinicId)"
                  >Select</vs-button
                >

                <vs-button
                  class="mt-2"
                  color="danger"
                  v-if="selectedClincsList.includes(clinic.clinicId)"
                  v-on:click="removeSelected(clinic.clinicId)"
                  >Deselect</vs-button
                >
                <div class="absolute circle">
                  <feather-icon
                    icon="CheckCircleIcon"
                    v-if="selectedClincsList.includes(clinic.clinicId)"
                    svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                  />
                </div>
              </div>
            </li>
            <li
              v-for="clinic in clinicsList"
              :key="clinic._id"
              class="w-full lg:hidden block"
            >
              <div class="rounded hover:shadow-md p-5 flex">
                <div class="flex items-center justify-center w-12">
                  <feather-icon
                    icon="CircleIcon"
                    v-if="!selectedClincsList.includes(clinic.clinicId)"
                    svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                    v-on:click="addSelected(clinic.clinicId)"
                  />
                  <feather-icon
                    icon="CheckCircleIcon"
                    v-if="selectedClincsList.includes(clinic.clinicId)"
                    svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                    v-on:click="removeSelected(clinic.clinicId)"
                  />
                </div>
                <div>
                  <h4 class="leading-loose">{{ clinic.name }}</h4>
                  <p class="leading-loose">
                    {{ clinic.address ? clinic.address.displayAddress : "" }}
                  </p>
                </div>

                <!-- <vs-button class="mt-2" v-if="!selectedClincsList.includes(clinic.clinicId)"
                  v-on:click="addSelected(clinic.clinicId)">Select</vs-button>

                <vs-button class="mt-2" color="danger" v-if="selectedClincsList.includes(clinic.clinicId)"
                  v-on:click="removeSelected(clinic.clinicId)">Deselect</vs-button> -->
              </div>
            </li>
          </ul>
        </div>
        <div class="flex flex-no-wrap items-center justify-end w-full">
          <p class="p-3">
            {{ selectedClincsList.length }}/{{ clinicsList.length }} Clinics
            Selected
          </p>
          <vs-button @click="handleRedirectToNext">Next</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import complianceComponent from "../../components/org-owner/compliance/complianceList.vue";
import { mapActions, mapMutations } from "vuex";
import _ from "lodash";
import jwt_decode from 'jwt-decode';

export default {
  components: {
    complianceComponent,
  },
  inject: ["getRoleRouteName"],
  data: () => ({
    organizationId: "",
    selectedClincsList: [],
    clinicsList: [],
    token: undefined,
    embedClinicId: undefined,
    userId: "",
  }),
  methods: {
    ...mapActions("organization", ["fetchAsociatedOrganizationAllClinics"]),
    async fetchclinics() {
      try {
        this.$vs.loading();
        const data = {
          id:this.organizationId,
          role: 'Org Owner',
        }
        if(this.token && this.token.length>0){
          const { id, userType } = jwt_decode(this.token);
          data.role = userType;
          data.userId = id;
        }
        const res = await this.fetchAsociatedOrganizationAllClinics(data);
        this.clinicsList = _.pullAll(
          res.data.data.map((clinic) => {
            if (clinic.drugEntries === 0 ) {
              return clinic;
            }
            return undefined;
          }),
          [undefined]
        );

        this.$vs.loading.close();
      } catch (err) {
        this.$vs.loading.close();
        console.error(err);
      }
    },
    addSelected(id) {
      this.selectedClincsList.push(id);
    },
    removeSelected(id) {
      this.selectedClincsList = _.pullAll([...this.selectedClincsList], [id]);
    },
    handleRedirectToNext() {
      if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}EmbededData`)){
        this.$router.push({
          path: '/drugbook/setup/clinic',
          query: { clinicsId: [...this.selectedClincsList], index: 0 },
        });
      }
      else{
        this.$router.push({
          name: this.getRoleRouteName("DrugbookTransfer"),
          query: { clinicsId: [...this.selectedClincsList], index: 0 },
        });
      }
    },
  },
  async created() {
    const {token, organizationId } = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}EmbededData`))||{};
    if(token && token.length>0){
      this.token = token
      this.userId = jwt_decode(this.token).id;
    }
    else{
      this.userId = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      )._id;
    }
    if(organizationId && organizationId.length>0){
      this.organizationId = organizationId
    } else if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    this.fetchclinics();
  },
};
</script>

<style>
.circle {
  top: 10px;
  right: 10px;
}
</style>
